import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export const routes = [
  {
    path: "/",
    component: () => import("@/views/ThatDay"),
    name: "ThatDay",
    meta: { title: "那年今日" },
    hidden: true,
  },
  {
    path: "/admin",
    component: () => import("@/views/AdminConsole"),
    hidden: true,
  },
  {
    path: "/test",
    component: () => import("@/views/TestPage"),
    hidden: true,
  },
];

const router = new Router({
  routes,
});

export default router;
